/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormattedMessage } from 'react-intl';
import { Accordion, Button, Icon } from 'semantic-ui-react';
import { MedicationalEfficacy } from '../../../types/ninox.types';
import { FormField } from '../../../components/molecules';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import style from '../../patientInfo/EditPatient.module.scss';
import classNames from 'classnames/bind';
import { AddMedicationEfficacyDialog } from '../../../components/organisms';
import { getFieldValue, mapValuesFrom0To10 } from '../../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { patientInfoSliceSelector, setPatientInfoUpdate } from '../patientInfoSlice';
import _ from 'lodash';

type EditPatientProps = {
  activeIndex: number;
  handleClick: (arg0: any, arg1: any) => void;
  onChangeFormField: (event: React.ChangeEvent<any>) => void;
};

export default function PatientMedication({ activeIndex, handleClick, onChangeFormField }: EditPatientProps) {
  const cx = classNames.bind(style);
  const [showMedicationEfficacyDialog, setShowMedicationEfficacyDialog] = useState(false);
  const [otherDrugTherapiesVisible, setOtherDrugTherapiesVisible] = useState(false);
  const [otherMedicinesVisible, setOtherMedicinesVisible] = useState(false);
  const [otherMedicinesOtherDiseasesVisible, setOtherMedicinesOtherDiseasesVisible] = useState(false);
  const [medicationEfficacyToEdit, setMedicationEfficacyToEdit] = useState<MedicationalEfficacy | null>(null);
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (patientInfoUpdate.therapy?.triedOtherDrugTherapies) {
      setOtherDrugTherapiesVisible(true);
    }

    if (patientInfoUpdate.therapy?.takeOtherMedicines) {
      setOtherMedicinesVisible(true);
    }

    if (patientInfoUpdate.therapy?.takeOtherMedicinesOtherDiseases) {
      setOtherMedicinesOtherDiseasesVisible(true);
    }
  }, [patientInfoUpdate]);

  const editMedicationEfficacy = (medicationalEfficacy: MedicationalEfficacy): void => {
    setMedicationEfficacyToEdit(medicationalEfficacy);
    setShowMedicationEfficacyDialog(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeTakeOtherMedicinesOtherDiseases = (event: React.ChangeEvent<any>): void => {
    const { checked } = event.target;

    if (checked) {
      setOtherMedicinesOtherDiseasesVisible(true);
    } else {
      setOtherMedicinesOtherDiseasesVisible(false);

      const updatePatient = { ...patientInfoUpdate };
      if (updatePatient.therapy?.otherMedicinesOtherDiseases) {
        updatePatient.therapy.otherMedicinesOtherDiseases = '';
      }
      dispatch(setPatientInfoUpdate(updatePatient));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeTakeOtherMedicines = (event: React.ChangeEvent<any>): void => {
    const { checked } = event.target;

    if (checked) {
      setOtherMedicinesVisible(true);
    } else {
      setOtherMedicinesVisible(false);

      const updatePatient = { ...patientInfoUpdate };
      if (updatePatient.therapy?.otherMedicines) {
        updatePatient.therapy.otherMedicines = '';
      }
      dispatch(setPatientInfoUpdate(updatePatient));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeTriedOtherDrugTherapies = (event: React.ChangeEvent<any>) => {
    const { checked } = event.target;

    if (checked) {
      setOtherDrugTherapiesVisible(true);
    } else {
      setOtherDrugTherapiesVisible(false);

      const updatePatient = { ...patientInfoUpdate };
      if (updatePatient.therapy?.otherDrugTherapies) {
        updatePatient.therapy.otherDrugTherapies = '';
      }
      dispatch(setPatientInfoUpdate(updatePatient));
    }
  };

  const onAddMedicationEfficacy = (medicationalEfficacy: MedicationalEfficacy): void => {
    if (medicationEfficacyToEdit) {
      if (patientInfoUpdate.therapy?.medicationalEfficacy) {
        for (let i = 0; i < patientInfoUpdate.therapy.medicationalEfficacy.length; i += 1) {
          if (patientInfoUpdate.therapy.medicationalEfficacy[i] === medicationEfficacyToEdit) {
            const efficacyUpdate = _.cloneDeep({ ...patientInfoUpdate.therapy });
            efficacyUpdate.medicationalEfficacy[i] = medicationalEfficacy;
            dispatch(setPatientInfoUpdate({ ...patientInfoUpdate, therapy: efficacyUpdate }));
          }
        }
      }
      setMedicationEfficacyToEdit(null);
    } else if (typeof patientInfoUpdate.therapy === 'undefined') {
      dispatch(setPatientInfoUpdate({ ...patientInfoUpdate, medicationalEfficacy: [medicationalEfficacy] }));
    } else if (typeof patientInfoUpdate.therapy.medicationalEfficacy === 'undefined') {
      dispatch(setPatientInfoUpdate({ ...patientInfoUpdate, medicationalEfficacy: [medicationalEfficacy] }));
    } else {
      const efficacyUpdate = _.cloneDeep({ ...patientInfoUpdate.therapy });
      efficacyUpdate.medicationalEfficacy.push(medicationalEfficacy);
      dispatch(setPatientInfoUpdate({ ...patientInfoUpdate, therapy: efficacyUpdate }));
    }
  };

  // const deleteMedicationEfficacy = (elem: MedicationalEfficacy): void => {
  //   const updatePatient = _.cloneDeep({...patientInfoUpdate});
  //   if (updatePatient.therapy && updatePatient.therapy.medicationalEfficacy) {
  //     const { medicationalEfficacy } = updatePatient.therapy;
  //     updatePatient.therapy.medicationalEfficacy = medicationalEfficacy.filter(
  //       (obj: MedicationalEfficacy) => obj.id !== elem.id,
  //     );
  //   }
  //   dispatch(setPatientInfoUpdate(updatePatient));
  // };

  return (
    <>
      <Accordion.Title active={activeIndex === 10} index={10} onClick={(event, data) => handleClick(event, data)}>
        <Icon name='dropdown' />
        <FormattedMessage id='patient.therapy' />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 10}>
        <Row>
          <Col className={cx({ CheckboxCol: true })}>
            <FormField
              type='checkbox'
              name='therapy.takeOtherMedicines'
              value='Takes other medicines?'
              checked={getFieldValue('boolean', patientInfoUpdate.therapy?.takeOtherMedicines)}
              onChange={(event) => {
                onChangeTakeOtherMedicines(event);
                onChangeFormField(event);
              }}
              displayType='regularCheckbox'
            >
              <FormattedMessage id='patient.takeOtherMedicines' />
            </FormField>
            {otherMedicinesVisible && (
              <FormField
                type='text'
                name='therapy.otherMedicines'
                value={getFieldValue('string', patientInfoUpdate.therapy?.otherMedicines)}
                onChange={onChangeFormField}
              >
                <FormattedMessage id='patient.otherMedicines' />
              </FormField>
            )}
            <FormField
              type='checkbox'
              name='therapy.takeOtherMedicinesOtherDiseases'
              value='Take other medicines for other diseases?'
              checked={getFieldValue('boolean', patientInfoUpdate.therapy?.takeOtherMedicinesOtherDiseases)}
              onChange={(event) => {
                onChangeTakeOtherMedicinesOtherDiseases(event);
                onChangeFormField(event);
              }}
              displayType='regularCheckbox'
            >
              <FormattedMessage id='patient.takeOtherMedicinesOtherDiseases' />
            </FormField>
            {otherMedicinesOtherDiseasesVisible && (
              <FormField
                type='text'
                name='therapy.otherMedicinesOtherDiseases'
                value={getFieldValue('string', patientInfoUpdate.therapy?.otherMedicinesOtherDiseases)}
                onChange={onChangeFormField}
              >
                <FormattedMessage id='patient.otherMedicinesOtherDiseases' />
              </FormField>
            )}
            <FormField
              type='checkbox'
              name='therapy.triedOtherDrugTherapies'
              value='Tried other drug therapies?'
              checked={getFieldValue('boolean', patientInfoUpdate.therapy?.triedOtherDrugTherapies)}
              onChange={(event) => {
                onChangeTriedOtherDrugTherapies(event);
                onChangeFormField(event);
              }}
              displayType='regularCheckbox'
            >
              <FormattedMessage id='patient.triedOtherDrugTherapies' />
            </FormField>
            {otherDrugTherapiesVisible && (
              <FormField
                type='text'
                name='therapy.otherDrugTherapies'
                value={getFieldValue('string', patientInfoUpdate.therapy?.otherDrugTherapies)}
                onChange={onChangeFormField}
              >
                <FormattedMessage id='patient.otherDrugTherapies' />
              </FormField>
            )}
          </Col>
          <Col>
            <FormField
              type='checkbox'
              name='cannabisTherapy.triedThcCannabis'
              value={getFieldValue('boolean', patientInfoUpdate.cannabisTherapy?.triedThcCannabis)}
              checked={getFieldValue('boolean', patientInfoUpdate.cannabisTherapy?.triedThcCannabis)}
              onChange={onChangeFormField}
              displayType='regularCheckbox'
            >
              <FormattedMessage id='patient.triedThcCannabis' />
            </FormField>
            <FormField
              type='checkbox'
              name='cannabisTherapy.hasRecommendationCannabisTherapy'
              value={getFieldValue('boolean', patientInfoUpdate.cannabisTherapy?.hasRecommendationCannabisTherapy)}
              checked={getFieldValue('boolean', patientInfoUpdate.cannabisTherapy?.hasRecommendationCannabisTherapy)}
              onChange={onChangeFormField}
              displayType='regularCheckbox'
            >
              <FormattedMessage id='patient.hasRecommendationCannabisTherapy' />
            </FormField>
            <FormField
              type='checkbox'
              name='cannabisTherapy.hasMedicalCannabisTreatment'
              value={getFieldValue('boolean', patientInfoUpdate.cannabisTherapy?.hasMedicalCannabisTreatment)}
              checked={getFieldValue('boolean', patientInfoUpdate.cannabisTherapy?.hasMedicalCannabisTreatment)}
              onChange={(event) => {
                onChangeFormField(event);
              }}
              displayType='regularCheckbox'
            >
              <FormattedMessage id='patient.hasMedicalCannabisTreatment' />
            </FormField>
            {getFieldValue('boolean', patientInfoUpdate.cannabisTherapy?.hasMedicalCannabisTreatment) && (
              <FormField
                type='text'
                name='cannabisTherapy.preparation'
                value={getFieldValue('string', patientInfoUpdate.cannabisTherapy?.preparation)}
                onChange={onChangeFormField}
              >
                <FormattedMessage id='patient.preparation' />
              </FormField>
            )}
            {getFieldValue('boolean', patientInfoUpdate.cannabisTherapy?.hasMedicalCannabisTreatment) && (
              <FormField
                type='checkbox'
                name='cannabisTherapy.hasImprovedCondition'
                value={getFieldValue('boolean', patientInfoUpdate.cannabisTherapy?.hasImprovedCondition)}
                checked={getFieldValue('boolean', patientInfoUpdate.cannabisTherapy?.hasImprovedCondition)}
                onChange={onChangeFormField}
                displayType='regularCheckbox'
              >
                <FormattedMessage id='patient.hasImprovedCondition' />
              </FormField>
            )}
          </Col>
        </Row>
        <div className={cx({ Spacer: true })}></div>
        <Row>
          <Col>
            <h2>
              <FormattedMessage id='patient.medicationEfficacy' />
            </h2>
            {patientInfoUpdate.therapy?.medicationalEfficacy &&
              patientInfoUpdate.therapy.medicationalEfficacy.length > 0 && (
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id='patient.queryDate' />
                      </th>
                      <th>
                        <FormattedMessage id='patient.effectiveness' />
                      </th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {patientInfoUpdate.therapy.medicationalEfficacy.map(
                      (entry: MedicationalEfficacy, index: number) => (
                        <tr key={index}>
                          <td>{getFieldValue('string', entry.queryDate)}</td>
                          <td>{mapValuesFrom0To10(entry.effectiveness)}</td>
                          <td>
                            <a
                              href='#'
                              onClick={(event) => {
                                event.preventDefault();
                                editMedicationEfficacy(entry);
                              }}
                            >
                              <FormattedMessage id='edit' />
                            </a>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              )}
            {(!patientInfoUpdate.therapy?.medicationalEfficacy ||
              (patientInfoUpdate.therapy?.medicationalEfficacy &&
                !patientInfoUpdate.therapy?.medicationalEfficacy.length)) && (
              <p>
                <FormattedMessage id='patient.noMedicationEfficacy' />
              </p>
            )}
            <Button onClick={() => setShowMedicationEfficacyDialog(true)}>
              <FormattedMessage id='patient.addMedicationEfficacy' />
            </Button>
            <AddMedicationEfficacyDialog
              show={showMedicationEfficacyDialog}
              setShow={setShowMedicationEfficacyDialog}
              onAddMedicationEfficacy={onAddMedicationEfficacy}
              medicationEfficacy={medicationEfficacyToEdit}
              clearEditedMedicationEfficacy={() => setMedicationEfficacyToEdit(null)}
            />
          </Col>
        </Row>
      </Accordion.Content>
    </>
  );
}
