import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Icon } from 'semantic-ui-react';
import { Col, Row, Table } from 'react-bootstrap';
import { Button } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { patientInfoSliceSelector } from '../patientInfoSlice';
import { toast } from 'react-toastify';
import { getPatientPayments, getPaymentInvoice } from '../../../utils/apiCalls';
import { ReactComponent as DownloadIcon } from '../../../images/download.svg';

type PatientPaymentProps = {
  activeIndex: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClick: (arg0: any, arg1: any) => void;
};

interface Payment {
  id: number;
  fields: PaymentFields;
}

interface PaymentFields {
  price: number;
  billedAmount: number;
  discountAmount: number;
  platformFee: number;
  doctorAmount: number;
  currency: string;
  icepayCheckoutId: string;
  paymentStatus: string;
  patientId: number;
  doctorId: number | null;
  productCode: string;
  couponCode: string;
  appointmentId: number | undefined;
  followupPrescriptionId: number | undefined;
  paymentStartedAt: Date;
  paymentCompletedAt: Date;
  forwardDone: boolean;
  forwardDate: Date;
  forwardAttempts: number;
  invoiceNumber: string;
}

export default function PatientPayments({ activeIndex, handleClick }: PatientPaymentProps) {
  const [payments, setPayments] = useState<[]>([]);
  const [paymentsError, setPaymentsError] = useState<string | null>(null);

  const intl = useIntl();
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        if (!patientInfoUpdate.id) {
          return;
        }
        const response = await getPatientPayments(patientInfoUpdate.id);

        if (!response.data) {
          setPayments([]);
          return;
        }

        setPaymentsError(null);
        setPayments(response.data);
      } catch (error) {
        if (error instanceof Error) {
          setPaymentsError(error.message);
        } else {
          setPaymentsError('An error occurred fetching payments.');
        }
        console.error('Error fetching patient payments (fetchPayments error):', error);
      }
    };

    fetchPayments();
  }, [patientInfoUpdate.id]);

  //Invoice numbers contain dashes and are therefore strings
  const handleDownload = async (paymentId: number, invoiceNumber: string) => {
    try {
      const response = await getPaymentInvoice(paymentId);

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;

      const filenameParts = [];

      if (patientInfoUpdate.firstname) {
        filenameParts.push(patientInfoUpdate.firstname);
      }
      if (patientInfoUpdate.lastname) {
        filenameParts.push(patientInfoUpdate.lastname);
      }
      if (invoiceNumber) {
        filenameParts.push(invoiceNumber);
      }
      filenameParts.push('Nowomed rechnung.pdf');

      const filename = filenameParts.join(' ');

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);

      toast.success(intl.formatMessage({ id: 'invoiceDownloaded' }));
    } catch (error) {
      console.error('Error downloading PDF:', error);
      toast.error(intl.formatMessage({ id: 'invoiceDownloadedError' }));
    }
  };

  const formatDate = (date: Date): string => {
    return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getFullYear()}`;
  };

  return (
    <>
      <Accordion.Title active={activeIndex === 14} index={14} onClick={(event, data) => handleClick(event, data)}>
        <Icon name='dropdown' />
        <FormattedMessage id='patient.payments' />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 14}>
        <Row>
          <Col>
            <div>
              {paymentsError && <div>{paymentsError}</div>}
              {!paymentsError && payments && payments.length === 0 && <FormattedMessage id='patient.noPamentsFound' />}
              {payments && payments.length > 0 && (
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id='patient.paymentId' />
                      </th>
                      <th>
                        <FormattedMessage id='patient.appointmentId' />
                      </th>
                      <th>
                        <FormattedMessage id='patient.paymentStatus' />
                      </th>
                      <th>
                        <FormattedMessage id='patient.paymentStartedAt' />
                      </th>
                      <th>
                        <FormattedMessage id='patient.price' />
                      </th>
                      <th>
                        <FormattedMessage id='patient.productCode' />
                      </th>
                      <th>
                        <FormattedMessage id='patient.invoiceNumber' />
                      </th>
                      <th>
                        <FormattedMessage id='patient.invoice' />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((payment: Payment) => (
                      <tr key={payment.id}>
                        <td>{payment.id}</td>
                        <td>{payment.fields.appointmentId}</td>
                        <td>{payment.fields.paymentStatus}</td>
                        <td>{formatDate(new Date(payment.fields.paymentStartedAt))}</td>
                        <td>{payment.fields.price}</td>
                        <td>{payment.fields.productCode}</td>
                        <td>{payment.fields.invoiceNumber || '-'}</td>
                        <td>
                          {payment.fields.invoiceNumber ? (
                            <Button onClick={() => handleDownload(payment.id, payment.fields.invoiceNumber)}>
                              <DownloadIcon />
                            </Button>
                          ) : (
                            <p>-</p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </Col>
        </Row>
      </Accordion.Content>
    </>
  );
}
