/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormattedMessage } from 'react-intl';
import { Accordion, Icon } from 'semantic-ui-react';
import { Col, Row } from 'react-bootstrap';
import { FormField } from '../../../components/molecules';
import { getFieldValue, getLocalizedChoices } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import { patientInfoSliceSelector } from '../patientInfoSlice';

type EditPatientProps = {
  activeIndex: number;
  onChangeFormField: (event: React.ChangeEvent<any>) => void;
  handleClick: (arg0: any, arg1: any) => void;
};

export default function PatientData({ activeIndex, handleClick, onChangeFormField }: EditPatientProps) {
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);

  const onChangeSex = (event: React.ChangeEvent<any>): void => {
    // It is possible to clear all the gynecological field when the sex value changes to male
    // TODO: find out if this is necessary
    return;

    // if (['female', 'other'].includes(event.target.value)) {
    //   setPatient((prevPatient) => ({
    //     ...prevPatient,
    //     secondaryDiagnosis: {
    //       movementDiseases: '',
    //       movementDiseasesOther: '',
    //       respiratoryDiseases: '',
    //       respiratoryDiseasesOther: '',
    //       cardiovascularDiseases: '',
    //       cardiovascularDiseasesOther: '',
    //       gastrointestinalDiseases: '',
    //       gastrointestinalDiseasesOther: '',
    //       neurologicalDiseases: '',
    //       neurologicalDiseasesOther: '',
    //       psychiatricDiseases: '',
    //       psychiatricDiseasesOther: '',
    //       infectiousDiseases: '',
    //       infectiousDiseasesOther: '',
    //       dermatology: '',
    //       dermatologyOther: '',
    //       hasOtherDiseases: false,
    //       cancerousDisease: false,
    //       cancerous: '',
    //       ...prevPatient.secondaryDiagnosis,
    //       gynecologicalAnamnesis: '',
    //       gynecologicalAnamnesisOther: '',
    //     },
    //   }));
    // }
  };

  return (
    <>
      <Accordion.Title active={activeIndex === 1} index={1} onClick={(event, data) => handleClick(event, data)}>
        <Icon name='dropdown' />
        <FormattedMessage id='patient.patientData' />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 1}>
        <Row>
          <Col>
            <FormField
              type='text'
              name='lastname'
              value={getFieldValue('string', patientInfoUpdate.lastname)}
              onChange={onChangeFormField}
            >
              <FormattedMessage id='patients.lastName' />
            </FormField>
          </Col>
          <Col>
            <FormField
              type='text'
              name='firstname'
              value={getFieldValue('string', patientInfoUpdate.firstname)}
              onChange={onChangeFormField}
            >
              <FormattedMessage id='patients.firstName' />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <FormField
              type='select'
              name='title'
              value={getFieldValue('string', patientInfoUpdate.title)}
              onChange={onChangeFormField}
              choices={getLocalizedChoices([
                ['', 'none'],
                ['doctor', 'Dr.'],
                ['professor', 'Prof.'],
              ])}
            >
              <FormattedMessage id='patients.title' />
            </FormField>
          </Col>
          <Col lg={4}>
            <FormField
              type='select'
              name='sex'
              value={getFieldValue('string', patientInfoUpdate.sex)}
              onChange={(event) => {
                onChangeFormField(event);
                onChangeSex(event);
              }}
              choices={getLocalizedChoices([
                ['male', 'male'],
                ['female', 'female'],
                ['other', 'other'],
              ])}
            >
              <FormattedMessage id='patients.sex' />
            </FormField>
          </Col>
          <Col>
            <FormField
              type='date'
              name='birthday'
              value={getFieldValue('string', patientInfoUpdate.birthday)}
              onChange={onChangeFormField}
            >
              <FormattedMessage id='patients.birthday' />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField
              type='select'
              name='nationality'
              value={getFieldValue('string', patientInfoUpdate.nationality)}
              onChange={onChangeFormField}
              choices={getLocalizedChoices([
                ['german', 'German'],
                ['swiss', 'Swiss'],
                ['polish', 'Polish'],
                ['danish', 'Danish'],
              ])}
            >
              <FormattedMessage id='patient.nationality' />
            </FormField>
          </Col>
          <Col>
            <FormField
              type='select'
              name='language'
              value={getFieldValue('string', patientInfoUpdate.language)}
              onChange={onChangeFormField}
              choices={getLocalizedChoices([
                ['german', 'German'],
                ['english', 'English'],
              ])}
            >
              <FormattedMessage id='patient.language' />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField
              type='tel'
              name='mobile'
              value={getFieldValue('string', patientInfoUpdate.mobile)}
              onChange={onChangeFormField}
            >
              <FormattedMessage id='patient.mobile' />
            </FormField>
          </Col>
          <Col>
            <FormField
              type='tel'
              name='landline'
              value={getFieldValue('string', patientInfoUpdate.landline)}
              onChange={onChangeFormField}
            >
              <FormattedMessage id='patient.landline' />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField
              type='email'
              name='email'
              value={getFieldValue('string', patientInfoUpdate.email)}
              onChange={onChangeFormField}
            >
              <FormattedMessage id='patients.email' />
            </FormField>
          </Col>
          <Col>
            <FormField
              type='select'
              name='insurance'
              value={getFieldValue('string', patientInfoUpdate.insurance)}
              onChange={onChangeFormField}
              choices={getLocalizedChoices([
                ['private', 'private'],
                ['statutory', 'statutory'],
                ['none', 'none'],
              ])}
            >
              <FormattedMessage id='patient.insurance' />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField
              type='number'
              name='size'
              value={getFieldValue('string', patientInfoUpdate.size)}
              onChange={onChangeFormField}
              min={1}
              max={999}
            >
              <FormattedMessage id='patient.size' />
            </FormField>
          </Col>
          <Col>
            <FormField
              type='number'
              name='weight'
              value={getFieldValue('string', patientInfoUpdate.weight)}
              onChange={onChangeFormField}
              min={1}
              max={999}
            >
              <FormattedMessage id='patient.weight' />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField
              type='number'
              name='bloodPressureFirst'
              value={getFieldValue('number', patientInfoUpdate.bloodPressureFirst)}
              onChange={onChangeFormField}
              min={0}
              max={220}
            >
              <FormattedMessage id='patient.bloodPressureFirst' />
            </FormField>
          </Col>
          <Col>
            <FormField
              type='number'
              name='bloodPressureSecond'
              value={getFieldValue('number', patientInfoUpdate.bloodPressureSecond)}
              onChange={onChangeFormField}
              min={0}
              max={220}
            >
              <FormattedMessage id='patient.bloodPressureSecond' />
            </FormField>
          </Col>
          <Col>
            <FormField
              type='number'
              name='pulse'
              value={getFieldValue('number', patientInfoUpdate.pulse)}
              onChange={onChangeFormField}
              min={0}
              max={220}
            >
              <FormattedMessage id='patient.pulse' />
            </FormField>
          </Col>
          <Col>
            <FormField
              type='number'
              name='oxygenSaturation'
              value={getFieldValue('number', patientInfoUpdate.oxygenSaturation)}
              onChange={onChangeFormField}
              min={0}
              max={99}
            >
              <FormattedMessage id='patient.oxygenSaturation' />
            </FormField>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField
              type='select'
              name='drinkingAlcohol'
              value={getFieldValue('string', patientInfoUpdate.drinkingAlcohol)}
              onChange={onChangeFormField}
              choices={getLocalizedChoices([
                ['', 'Not set'],
                ['no', 'No'],
                ['daily', 'Daily'],
                ['weekly', 'Weekly'],
                ['monthly', 'Monthly'],
              ])}
            >
              <FormattedMessage id='patient.drinkingAlcohol' />
            </FormField>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <FormField
              type='textarea'
              name='comment'
              value={getFieldValue('string', patientInfoUpdate.comment)}
              onChange={onChangeFormField}
            >
              <FormattedMessage id='patient.comment' />
            </FormField>
          </Col>
        </Row>
      </Accordion.Content>
    </>
  );
}
