/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import type { Absence as AbsenceType } from '../../../../types/absence.type';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header, Table } from 'semantic-ui-react';
import { doctorsSliceSelector, fetchDoctorAbsences, createDoctorAbsence } from '../../doctorsSlice';
import moment from 'moment';
import DoctorInfoAbsencesItem from './doctorInfoAbsensesItem';

export default function DoctorInfoAbsences(props: any) {
  const { doctorId } = props;
  const { doctorAbsences } = useSelector(doctorsSliceSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!doctorAbsences || !doctorAbsences.length) {
      dispatch(fetchDoctorAbsences(doctorId));
    }
  }, []);

  const renderDoctorAbsencesList = (doctorAbsences: any) =>
    !doctorAbsences.length ? (
      <Table.Row>
        <Table.Cell textAlign='center' colSpan={9}>
          <Header as='h4'>
            <FormattedMessage id='noAbsencesFound' />
          </Header>
        </Table.Cell>
      </Table.Row>
    ) : (
      doctorAbsences.map((item: AbsenceType, i: number) => (
        <DoctorInfoAbsencesItem key={i} item={item} i={i} doctorId={doctorId} />
      ))
    );

  const addAbsences = () => {
    const now = moment();
    const absence: AbsenceType = {
      startDate: now.toISOString(),
      endDate: now.toISOString(),
      // @ts-ignore
      subDoctorsIds: JSON.stringify([]),
    };
    const id = doctorId;
    const payload: any = {
      absence,
      id,
    };
    dispatch(createDoctorAbsence(payload));
  };

  return (
    <div>
      <article>
        <div>
          <div>
            <Table basic='very'>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <FormattedMessage id='dateStart' />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id='dateEnd' />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id='substituteDoctors' />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    <FormattedMessage id='actions' />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{renderDoctorAbsencesList(doctorAbsences)}</Table.Body>
            </Table>
          </div>
          <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            <Button size='tiny' circular onClick={() => addAbsences()} color='blue' icon='add' />
          </div>
        </div>
      </article>
    </div>
  );
}
