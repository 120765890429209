/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  doctorsSliceSelector,
  fetchDoctorsAppointmentsStats,
  setIsDoctorSelected,
  fetchAllDoctors,
} from './doctorsSlice';
import { FormattedMessage } from 'react-intl';
import { Segment, Dimmer, Loader, Button, List, Progress } from 'semantic-ui-react';
import { DoctorInfoComponent } from './doctorInfo/doctorInfo.component';

export default function DoctorsComponent() {
  const { doctorsAppointmentsStats, isDoctorSelecetd } = useSelector(doctorsSliceSelector);
  const [selectedDoctor, setSelectedDoctor] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!doctorsAppointmentsStats.length) {
      dispatch(fetchDoctorsAppointmentsStats());
      dispatch(fetchAllDoctors());
    }
  }, [doctorsAppointmentsStats]);

  const onSelectDoctor = (item: any) => {
    setSelectedDoctor(item);
    dispatch(setIsDoctorSelected(true));
  };

  const renderDoctorsAppointmentsStatsList = (appointments: any) =>
    !appointments.length ? (
      <Segment raised inverted className='segment-style'>
        No Doctors Found!
      </Segment>
    ) : (
      doctorsAppointmentsStats.map((item: any, i: number) => (
        <Segment.Group horizontal key={i}>
          <Segment raised inverted className='segment-style'>
            <List>
              <List.Item>
                Name: <b>{item.name}</b>
              </List.Item>
              <List.Item>Email: {item.email || '-'}</List.Item>
              <List.Item>ID: {item.id}</List.Item>
            </List>
          </Segment>
          <Segment raised inverted className='segment-style'>
            <List>
              <List.Item>
                <div className='progressTitle'>
                  <b>Auslastung 14 Tage</b>
                </div>
                <div className={item.twoWeekStats.percentage > 80 ? 'onlineTextcolorRed' : 'onlineTextcolorGreen'}>
                  {`${item.twoWeekStats.total} verfügbar (${item.twoWeekStats.booked} gebucht)`}
                </div>
                <Progress
                  progress
                  percent={item.twoWeekStats.percentage}
                  size='small'
                  color={item.twoWeekStats.percentage > 80 ? 'red' : 'green'}
                />
              </List.Item>
            </List>
          </Segment>
          <Segment raised inverted className='segment-style'>
            <List>
              <List.Item>
                <div className='progressTitle'>
                  <b>Auslastung Total</b>
                </div>
                <div className={item.totalStats.percentage > 80 ? 'onlineTextcolorRed' : 'onlineTextcolorGreen'}>
                  {`${item.totalStats.total} verfügbar (${item.totalStats.booked} gebucht)`}
                </div>
                <Progress
                  progress
                  percent={item.totalStats.percentage}
                  size='small'
                  color={item.totalStats.percentage > 80 ? 'red' : 'green'}
                />
              </List.Item>
            </List>
          </Segment>
          <Segment raised inverted className='segment-style practicesSegment1 verticalCenter' textAlign='right'>
            <span>
              <Button onClick={() => onSelectDoctor(item)} primary floated='right'>
                Details
              </Button>
            </span>
          </Segment>
        </Segment.Group>
      ))
    );

  const appointmentsStatsListLoading = (
    <Dimmer active inverted>
      <Loader className='loading-spinner' active inline='centered'>
        <FormattedMessage id='loading' />
      </Loader>
    </Dimmer>
  );

  return (
    <div className='details_container'>
      {!isDoctorSelecetd ? (
        <div>
          {doctorsAppointmentsStats.length !== 0
            ? renderDoctorsAppointmentsStatsList(doctorsAppointmentsStats)
            : appointmentsStatsListLoading}
        </div>
      ) : (
        <DoctorInfoComponent selectedDoctor={selectedDoctor} />
      )}
    </div>
  );
}
