/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormattedMessage } from 'react-intl';
import { Accordion, Icon } from 'semantic-ui-react';
import { Col, Row } from 'react-bootstrap';
import { FormField } from '../../../components/molecules';
import React from 'react';
import { SelectOption } from '../../../components/atoms/Input/Input';
import { getFieldValue, getLocalizedChoices, isInMultiSelect } from '../../../utils/helpers';
import { useSelector } from 'react-redux';
import { patientInfoSliceSelector } from '../patientInfoSlice';

type EditPatientProps = {
  activeIndex: number;
  handleClick: (arg0: any, arg1: any) => void;
  onChangeFormField: (event: React.ChangeEvent<any>) => void;
  onMultiSelectChange: (name: string, options: readonly SelectOption[]) => void;
};

export default function PatientSecondaryDiagnosis({
  activeIndex,
  handleClick,
  onChangeFormField,
  onMultiSelectChange,
}: EditPatientProps) {
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeOtherDiseases = (event: React.ChangeEvent<any>): void => {
    // It is possible to clear all secondary diagnosis fields when the checkbox for further diseases gets unchecked.
    // TODO: find out if this is necessary
    return;

    // if (!event.target.checked) {
    //   setPatient((prevPatient) => ({
    //     ...prevPatient,
    //     secondaryDiagnosis: {
    //       ...prevPatient.secondaryDiagnosis,
    //       movementDiseases: '',
    //       movementDiseasesOther: '',
    //       respiratoryDiseases: '',
    //       respiratoryDiseasesOther: '',
    //       cardiovascularDiseases: '',
    //       cardiovascularDiseasesOther: '',
    //       gastrointestinalDiseases: '',
    //       gastrointestinalDiseasesOther: '',
    //       neurologicalDiseases: '',
    //       neurologicalDiseasesOther: '',
    //       psychiatricDiseases: '',
    //       psychiatricDiseasesOther: '',
    //       infectiousDiseases: '',
    //       infectiousDiseasesOther: '',
    //       dermatology: '',
    //       dermatologyOther: '',
    //       gynecologicalAnamnesis: '',
    //       gynecologicalAnamnesisOther: '',
    //       hasOtherDiseases: false,
    //       cancerousDisease: false,
    //       cancerous: '',
    //     },
    //   }));
    // }
  };

  return (
    <>
      <Accordion.Title active={activeIndex === 8} index={8} onClick={(event, data) => handleClick(event, data)}>
        <Icon name='dropdown' />
        <FormattedMessage id='patient.secondaryDiagnosis' />
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 8}>
        <Row>
          <Col>
            <FormField
              type='checkbox'
              name='secondaryDiagnosis.cancerousDisease'
              value='Cancerous disease?'
              checked={getFieldValue('boolean', patientInfoUpdate.secondaryDiagnosis?.cancerousDisease)}
              onChange={onChangeFormField}
              displayType='regularCheckbox'
            >
              <FormattedMessage id='patient.cancerousDisease' />
            </FormField>
            {patientInfoUpdate.secondaryDiagnosis?.cancerousDisease && (
              <FormField
                type='input'
                name='secondaryDiagnosis.cancerous'
                value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.cancerous)}
                onChange={onChangeFormField}
              ></FormField>
            )}
            <FormField
              type='checkbox'
              name='secondaryDiagnosis.hasOtherDiseases'
              value='Has other diseases?'
              checked={getFieldValue('boolean', patientInfoUpdate.secondaryDiagnosis?.hasOtherDiseases)}
              onChange={(event) => {
                onChangeFormField(event);
                onChangeOtherDiseases(event);
              }}
              displayType='regularCheckbox'
            >
              <FormattedMessage id='patient.otherDiseases' />
            </FormField>
          </Col>
          <Col></Col>
        </Row>
        {patientInfoUpdate.secondaryDiagnosis?.hasOtherDiseases && (
          <React.Fragment>
            <Row>
              <Col>
                <FormField
                  type='multiselect'
                  name='secondaryDiagnosis.movementDiseases'
                  value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.movementDiseases)}
                  onMultiSelectChange={onMultiSelectChange}
                  choices={getLocalizedChoices([
                    ['osteoarthritis', 'Osteoarthritis'],
                    ['gout', 'Gout'],
                    ['rheumatoidArthritis', 'Rheumatoid arthritis'],
                    ['fibromyalgia', 'Fibromyalgia'],
                    ['other', 'Other'],
                  ])}
                >
                  <FormattedMessage id='patient.movementDiseases' />
                </FormField>

                {isInMultiSelect(patientInfoUpdate.secondaryDiagnosis?.movementDiseases, 'other') && (
                  <FormField
                    type='input'
                    name='secondaryDiagnosis.movementDiseasesOther'
                    value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.movementDiseasesOther)}
                    onChange={onChangeFormField}
                  ></FormField>
                )}
              </Col>
              <Col>
                <FormField
                  type='multiselect'
                  name='secondaryDiagnosis.respiratoryDiseases'
                  value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.respiratoryDiseases)}
                  onMultiSelectChange={onMultiSelectChange}
                  choices={getLocalizedChoices([
                    ['bronchialAsthma', 'Bronchial Asthma'],
                    ['copd', 'COPD'],
                    ['pneumonia', 'Pneumonia'],
                    ['other', 'other'],
                  ])}
                >
                  <FormattedMessage id='patient.respiratoryDiseases' />
                </FormField>
                {isInMultiSelect(patientInfoUpdate.secondaryDiagnosis?.respiratoryDiseases, 'other') && (
                  <FormField
                    type='input'
                    name='secondaryDiagnosis.respiratoryDiseasesOther'
                    value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.respiratoryDiseasesOther)}
                    onChange={onChangeFormField}
                  ></FormField>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <FormField
                  type='multiselect'
                  name='secondaryDiagnosis.cardiovascularDiseases'
                  value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.cardiovascularDiseases)}
                  onMultiSelectChange={onMultiSelectChange}
                  choices={getLocalizedChoices([
                    ['highBloodPressure', 'High blood pressure'],
                    ['heartFailure', 'Heart failure'],
                    ['heartAttack', 'Heart attack'],
                    ['bloodClottingDisorder', 'Blood clotting disorder'],
                    ['cardiacArrhythmia', 'Cardiac Arrhythmia'],
                    ['coronaryHeartDisease', 'Coronary Heart Disease'],
                    ['thrombosis', 'Thrombosis'],
                    ['other', 'Other'],
                  ])}
                >
                  <FormattedMessage id='patient.cardiovascularDiseases' />
                </FormField>
                {isInMultiSelect(patientInfoUpdate.secondaryDiagnosis?.cardiovascularDiseases, 'other') && (
                  <FormField
                    type='input'
                    name='secondaryDiagnosis.cardiovascularDiseasesOther'
                    value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.cardiovascularDiseasesOther)}
                    onChange={onChangeFormField}
                  ></FormField>
                )}
              </Col>
              <Col>
                <FormField
                  type='multiselect'
                  name='secondaryDiagnosis.gastrointestinalDiseases'
                  value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.gastrointestinalDiseases)}
                  onMultiSelectChange={onMultiSelectChange}
                  choices={getLocalizedChoices([
                    ['crohnsDisease', 'Crohns disease'],
                    ['irritableBowelSyndrome', 'Irritable bowel syndrome'],
                    ['cachexia', 'Cachexia'],
                    ['metabolicDisorders', 'Metabolic disorders'],
                    ['ulcerativeColitis', 'Ulcerative Colitis'],
                    ['gastritis', 'Gastritis'],
                    ['diabetesMellitus', 'Diabetes Mellitus'],
                    ['other', 'Other'],
                  ])}
                >
                  <FormattedMessage id='patient.gastrointestinalDiseases' />
                </FormField>
                {isInMultiSelect(patientInfoUpdate.secondaryDiagnosis?.gastrointestinalDiseases, 'other') && (
                  <FormField
                    type='input'
                    name='secondaryDiagnosis.gastrointestinalDiseasesOther'
                    value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.gastrointestinalDiseasesOther)}
                    onChange={onChangeFormField}
                  ></FormField>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <FormField
                  type='multiselect'
                  name='secondaryDiagnosis.neurologicalDiseases'
                  value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.neurologicalDiseases)}
                  onMultiSelectChange={onMultiSelectChange}
                  choices={getLocalizedChoices([
                    ['chronicHeadache', 'Chronic headache'],
                    ['epilepsy', 'Epilepsy'],
                    ['tourettesSyndrome', 'Tourettes syndrome'],
                    ['parkinsonsDisease', 'Parkinsons disease'],
                    ['paraplegia', 'Paraplegia'],
                    ['migraine', 'Migraine'],
                    ['multipleSclerosis', 'Multiple sclerosis'],
                    ['adhs', 'ADHS'],
                    ['polyneuropathy', 'Polyneuropathy'],
                    ['other', 'Other'],
                  ])}
                >
                  <FormattedMessage id='patient.neurologicalDiseases' />
                </FormField>
                {isInMultiSelect(patientInfoUpdate.secondaryDiagnosis?.neurologicalDiseases, 'other') && (
                  <FormField
                    type='input'
                    name='secondaryDiagnosis.neurologicalDiseasesOther'
                    value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.neurologicalDiseasesOther)}
                    onChange={onChangeFormField}
                  ></FormField>
                )}
              </Col>
              <Col>
                <FormField
                  type='multiselect'
                  name='secondaryDiagnosis.psychiatricDiseases'
                  value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.psychiatricDiseases)}
                  onMultiSelectChange={onMultiSelectChange}
                  choices={getLocalizedChoices([
                    ['anxeityDisorder', 'Anxiety disorder'],
                    ['adjustmentDisorder', 'Adjustment disorder'],
                    ['depression', 'Depression'],
                    ['postTraumaticStressDisorder', 'Post traumatic stress disorder'],
                    ['psychoses', 'Psychoses'],
                    ['psychosesFamily', 'Psychoses in family'],
                    ['sleepDisorder', 'Sleep disorder'],
                    ['addiction', 'Addiction'],
                    ['other', 'Other'],
                  ])}
                >
                  <FormattedMessage id='patient.psychiatricDiseases' />
                </FormField>
                {isInMultiSelect(patientInfoUpdate.secondaryDiagnosis?.psychiatricDiseases, 'other') && (
                  <FormField
                    type='input'
                    name='secondaryDiagnosis.psychiatricDiseasesOther'
                    value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.psychiatricDiseasesOther)}
                    onChange={onChangeFormField}
                  ></FormField>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <FormField
                  type='multiselect'
                  name='secondaryDiagnosis.infectiousDiseases'
                  value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.infectiousDiseases)}
                  onMultiSelectChange={onMultiSelectChange}
                  choices={getLocalizedChoices([
                    ['hepatits', 'Hepatitis'],
                    ['tuberculosis', 'Tuberculosis'],
                    ['aids', 'AIDS'],
                    ['lymeDisease', 'Lyme disease'],
                    ['other', 'Other'],
                  ])}
                >
                  <FormattedMessage id='patient.infectiousDiseases' />
                </FormField>
                {isInMultiSelect(patientInfoUpdate.secondaryDiagnosis?.infectiousDiseases, 'other') && (
                  <FormField
                    type='input'
                    name='secondaryDiagnosis.infectiousDiseasesOther'
                    value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.infectiousDiseasesOther)}
                    onChange={onChangeFormField}
                  ></FormField>
                )}
              </Col>
              <Col>
                <FormField
                  type='multiselect'
                  name='secondaryDiagnosis.dermatology'
                  value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.dermatology)}
                  onMultiSelectChange={onMultiSelectChange}
                  choices={getLocalizedChoices([
                    ['acneInversa', 'Acne Inversa'],
                    ['hyperhidrosis', 'Hyperhidrosis'],
                    ['psoriasis', 'Psoriasis'],
                    ['neurodermatitis', 'Neurodermatitis'],
                    ['urticaria', 'Urticaria'],
                    ['pruritus', 'Pruritus'],
                    ['other', 'Other'],
                  ])}
                >
                  <FormattedMessage id='patient.dermatology' />
                </FormField>
                {isInMultiSelect(patientInfoUpdate.secondaryDiagnosis?.dermatology, 'other') && (
                  <FormField
                    type='input'
                    name='secondaryDiagnosis.dermatologyOther'
                    value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.dermatologyOther)}
                    onChange={onChangeFormField}
                  ></FormField>
                )}
              </Col>
            </Row>
          </React.Fragment>
        )}
        <Row>
          <Col>
            {['female', 'other'].includes(patientInfoUpdate.sex) && (
              <>
                <FormField
                  type='multiselect'
                  name='secondaryDiagnosis.gynecologicalAnamnesis'
                  value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.gynecologicalAnamnesis)}
                  onMultiSelectChange={onMultiSelectChange}
                  choices={getLocalizedChoices([
                    ['pregnancy', 'Pregnancy'],
                    ['pregnancyInPlanning', 'Pregnancy in planning'],
                    ['breastfeed', 'Breastfeed'],
                    ['endometriosis', 'Endometriosis'],
                    ['bladderSpasms', 'Bladder spasms'],
                    ['other', 'Other'],
                  ])}
                >
                  <FormattedMessage id='patient.gynecologicalAnamnesis' />
                </FormField>
                {isInMultiSelect(patientInfoUpdate.secondaryDiagnosis?.gynecologicalAnamnesis, 'other') && (
                  <FormField
                    type='input'
                    name='secondaryDiagnosis.gynecologicalAnamnesisOther'
                    value={getFieldValue('string', patientInfoUpdate.secondaryDiagnosis?.gynecologicalAnamnesisOther)}
                    onChange={onChangeFormField}
                  ></FormField>
                )}
              </>
            )}
          </Col>
          <Col></Col>
        </Row>
      </Accordion.Content>
    </>
  );
}
