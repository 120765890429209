/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { practicesSliceSelector, fetchPractices, setIsDisplayInfo } from './practicesSlice';
import { Dimmer, Loader, Segment, List, Progress, Button } from 'semantic-ui-react';
import PracticeElement from './practicesInfo.component';
import { FormattedMessage } from 'react-intl';

export default function PractisesComponent() {
  const { practices, isDisplayInfo } = useSelector(practicesSliceSelector);
  const [selectedPractice, setSelectedPractice] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!practices.length) {
      dispatch(fetchPractices());
    }
  }, [practices]);

  const showInfoComponent = (practiceItem: any) => {
    dispatch(setIsDisplayInfo(true));
    setSelectedPractice(practiceItem);
  };

  const renderPracticesList = (appointments: any) =>
    !appointments.length ? (
      <Segment color='teal'>No practices found!</Segment>
    ) : (
      practices.map((item: any, i: number) => (
        <Segment.Group horizontal key={i}>
          <Segment raised inverted className='segment-style'>
            <List>
              <List.Item>
                Name: <b>{item.name}</b>
              </List.Item>
              <List.Item>ID: {item.id}</List.Item>
            </List>
          </Segment>
          <Segment raised inverted className='segment-style'>
            <List>
              <List.Item>
                <div className='progressTitle'>
                  <b>Auslastung 14 Tage</b>
                </div>
                <div
                  className={
                    item.twoWeekStats.offlineFirstAppointments.percentage > 80
                      ? 'onlineTextcolorRed'
                      : 'onlineTextcolorBlue'
                  }
                ></div>
                {`Erstgespräche: ${item.twoWeekStats.offlineFirstAppointments.total} Std. verfügbar (${item.twoWeekStats.offlineFirstAppointments.booked} Std. gebucht)`}
                <Progress
                  progress
                  percent={item.twoWeekStats.offlineFirstAppointments.percentage}
                  size='small'
                  color={item.twoWeekStats.offlineFirstAppointments.percentage > 80 ? 'red' : 'blue'}
                />
              </List.Item>
            </List>
          </Segment>
          <Segment raised inverted className='segment-style'>
            <List>
              <List.Item>
                <div className='progressTitle'>
                  <b>Auslastung Total</b>
                </div>
                <div
                  className={
                    item.totalStats.offlineFirstAppointments.percentage > 80
                      ? 'onlineTextcolorRed'
                      : 'onlineTextcolorBlue'
                  }
                ></div>
                {`Erstgespräche: ${item.totalStats.offlineFirstAppointments.total} Std. verfügbar (${item.totalStats.offlineFirstAppointments.booked} Std. gebucht)`}
                <Progress
                  progress
                  percent={item.totalStats.offlineFirstAppointments.percentage}
                  size='small'
                  color={item.totalStats.offlineFirstAppointments.percentage > 80 ? 'red' : 'blue'}
                />
              </List.Item>
            </List>
          </Segment>
          <Segment raised inverted className='segment-style practicesSegment1' textAlign='right'>
            <Button
              className='detailsPractiseBotton'
              primary
              floated='right'
              onClick={() => {
                showInfoComponent({ ...item });
              }}
            >
              Details
            </Button>
          </Segment>
        </Segment.Group>
      ))
    );

  const practicesListLoading = (
    <Dimmer active inverted>
      <Loader className='loading-spinner' active inline='centered'>
        <FormattedMessage id='loading' />
      </Loader>
    </Dimmer>
  );

  return (
    <div className='details_container'>
      {!isDisplayInfo ? (
        <div>{practices.length !== 0 ? renderPracticesList(practices) : practicesListLoading}</div>
      ) : (
        <PracticeElement practice={selectedPractice} />
      )}
    </div>
  );
}
