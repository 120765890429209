/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

const AUTH_SLICE = 'AUTH';

// Code to check if the token has expired ---LATER NOT NOW THE IMPLIMATATION
// const initialToken = () => {
//   const token = localStorage.getItem('Token_id');
//   if (!token) return false;

//   try {
//     const { exp } = jwt_decode(token);

//     if (exp < Date.now() / 1000) {
//       localStorage.removeItem('Token_id');
//       return false;
//     }
//   } catch (e) {
//     return false;
//   }
//   return token;
// };

const initialToken = localStorage.getItem('Token');

const initialState = {
  auth: !!initialToken,
  token: initialToken,
  user: null,
  authError: null,
};

export const authSlice = createSlice({
  name: AUTH_SLICE,
  initialState,
  reducers: {
    setAuth: (state, action) => {
      const token = action.payload;
      state.auth = !!token;
      state.token = token;
      if (token) {
        localStorage.setItem('Token', token);
      } else localStorage.removeItem('Token');
    },
    setCurrentUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
      if (state.token) localStorage.setItem('Token_id', action.payload);
      else localStorage.removeItem('Token_id');
    },
    logOut: () => {
      localStorage.removeItem('Token');
      window.location.reload();
    },
    setAuthError: (state, action) => {
      state.authError = action.payload;
    },
    login: () => {}, // call watcherSaga.
  },
});

export const { setAuth, setCurrentUser, setToken, login, setAuthError, logOut } = authSlice.actions;

export const authSliceSelector = (state: any) => state.auth;

export default authSlice.reducer;
