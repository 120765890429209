/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

const GLOBALS_SLICE = 'GLOBALS_SLICE';

const initialState = {
  isLoading: false,
  language: 'de',
};

export const globalsSlice = createSlice({
  name: GLOBALS_SLICE,
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = globalsSlice.actions;

export const globalSliceSelector = (state: any) => state.global;

export default globalsSlice.reducer;
