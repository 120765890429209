/* eslint-disable no-use-before-define */
import React from 'react';
import classNames from 'classnames/bind';
import loader from '../../../images/loader.gif';
import style from './Loader.module.scss';

const cx = classNames.bind(style);

type LoaderProps = {
  show: boolean;
};

export default function Loader({ show }: LoaderProps): JSX.Element {
  if (show) {
    return (
      <div className={cx({ LoaderOverlay: true, Visible: show })}>
        <div className={cx({ LoaderBox: true })}>
          <img src={loader} alt='loading animation' />
          <div className={cx({ LoaderText: true })}>Loading...</div>
        </div>
      </div>
    );
  }

  return <React.Fragment></React.Fragment>;
}
