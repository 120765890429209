import { useIntl } from 'react-intl';

export const isInMultiSelect = (array: string | undefined, value: string): boolean => {
  if (array === undefined) {
    return false;
  }
  const stringArray = array.split(', ');
  const result = stringArray.indexOf(value);

  return result > -1;
};

export const mapValuesFrom0To10 = (value: string): string => {
  if (value === '11') {
    return '0';
  }

  return value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFieldValue = (type: string, fieldValue?: any): any => {
  if (typeof fieldValue !== 'undefined') {
    return fieldValue;
  }

  switch (type) {
    case 'string':
      return '';
    case 'boolean':
      return false;
    case 'number':
      return 0;
    default:
      return null;
  }
};

/**
 * Localizes all choices (the labels of them) in an array. The arrays for choices have the form
 * [[value1, label1], [value2, label2], ...]
 * @param choices The choices array
 * @returns An array of the same form with localized labels.
 */
export const getLocalizedChoices = (choices: string[][]): string[][] =>
  choices.map((choice) => [choice[0], useIntl().formatMessage({ id: `patient.choices.${choice[0]}` })]);
