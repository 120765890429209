import { Select, Header, Segment, Icon, DropdownProps } from 'semantic-ui-react';
import nowomedLogo from '../../assets/favicon.png';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage, globalSliceSelector } from '../../app/appSlice';
import { SyntheticEvent } from 'react';

export default function DashboardComponent() {
  const dispatch = useDispatch();

  const globalState = useSelector(globalSliceSelector);
  const currentLanguage: string | undefined = globalState.language;

  const handleLanguageChange = (e: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    if (typeof data.value === 'string') {
      dispatch(setLanguage(data.value));
    }
  };

  const languageOptions = [
    { key: 'en', value: 'en', text: 'English' },
    { key: 'de', value: 'de', text: 'Deutsch' },
  ];

  return (
    <div className='details_container'>
      <Select
        placeholder='Select your language'
        value={currentLanguage}
        onChange={handleLanguageChange}
        options={languageOptions}
      />

      <Segment placeholder>
        <Header icon>
          <Icon name='chart pie' />
          <img src={nowomedLogo} alt='Nowomed logo' />
          nowomed Dashboard Coming Soon
        </Header>
      </Segment>
    </div>
  );
}
