/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { Address } from '../../../types/ninox.types';
import { FormDialog, FormField } from '../../molecules';

type AddAddressDialogProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  onAddAddress: (address: Address) => void;
  address: Address | null;
  patientId: string;
  clearEditedAddress: () => void;
};

export default function AddAddressDialog(props: AddAddressDialogProps): JSX.Element {
  const intl = useIntl();

  const emptyAddress = {
    street: '',
    houseNumber: '',
    city: '',
    postcode: '',
    country: 'germany',
    doctorId: '',
  };

  const [address, setAddress] = useState<Address>({ ...emptyAddress });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeFormField = (event: React.ChangeEvent<any>): void => {
    const { name, value } = event.target;
    setAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
  };

  const onSave = () => {
    props.onAddAddress(address);
    props.setShow(false);
    setAddress({ ...emptyAddress });
  };

  const onClose = () => {
    props.clearEditedAddress();
    setAddress({ ...emptyAddress });
  };

  useEffect(() => {
    if (props.address) {
      setAddress(props.address);
    }
  }, [props.address]);

  // eslint-disable-next-line max-len
  const getLocalizedChoices = (choices: string[][]): string[][] =>
    choices.map((choice) => [choice[0], intl.formatMessage({ id: `patient.choices.${choice[0]}` })]);

  return (
    <FormDialog
      title={intl.formatMessage({ id: 'patient.editAddress' })}
      show={props.show}
      setShow={props.setShow}
      onSave={onSave}
      onClose={onClose}
    >
      <FormField
        type='text'
        placeholder={intl.formatMessage({ id: 'patient.enterStreet' })}
        name='street'
        value={address.street}
        onChange={onChangeFormField}
      >
        <FormattedMessage id='patient.street' />
      </FormField>
      <FormField
        type='number'
        placeholder={intl.formatMessage({ id: 'patient.enterHouseNumber' })}
        name='houseNumber'
        value={address.houseNumber}
        onChange={onChangeFormField}
      >
        <FormattedMessage id='patient.houseNumber' />
      </FormField>
      <FormField
        type='text'
        placeholder={intl.formatMessage({ id: 'patient.enterCity' })}
        name='city'
        value={address.city}
        onChange={onChangeFormField}
      >
        <FormattedMessage id='patient.city' />
      </FormField>
      <FormField
        type='text'
        placeholder={intl.formatMessage({ id: 'patient.enterPostcode' })}
        name='postcode'
        value={address.postcode}
        onChange={onChangeFormField}
      >
        <FormattedMessage id='patient.postcode' />
      </FormField>
      <FormField
        type='select'
        name='country'
        value={address.country}
        onChange={onChangeFormField}
        choices={getLocalizedChoices([
          ['germany', 'Germany'],
          ['switzerland', 'Switzerland'],
          ['poland', 'Poland'],
          ['denmark', 'Denmark'],
        ])}
      >
        <FormattedMessage id='patient.country' />
      </FormField>
    </FormDialog>
  );
}
