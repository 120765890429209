/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Header, Loader } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getLocalizedDateTime } from '../../utils';
import { appointmentsSliceSelector, fetchAppointments, sortAppointments } from './appointmentsSlice';

export default function AppointmentsComponent() {
  const [column, setColumn] = useState('startDate');
  const [directionArrow, setDirectionArrow] = useState('descending');
  const [direction, setDirection] = useState(1);
  const intl = useIntl();
  const { appointments } = useSelector(appointmentsSliceSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!appointments.length) {
      dispatch(fetchAppointments());
    }
  }, [appointments]);

  const renderAppointmentsList = (appointments: any) =>
    !appointments.length ? (
      <Table.Row>
        <Table.Cell textAlign='center' colSpan={9}>
          <Header as='h4'>No appointments found!</Header>
        </Table.Cell>
      </Table.Row>
    ) : (
      appointments.map((appointmentsItem: any, i: number) => (
        <Table.Row key={i}>
          <Table.Cell> {appointmentsItem.id} </Table.Cell>
          <Table.Cell>
            {getLocalizedDateTime(intl, appointmentsItem.startDate)} <b>-</b>{' '}
            {getLocalizedDateTime(intl, appointmentsItem.endDate)}
          </Table.Cell>
          <Table.Cell>
            {' '}
            {appointmentsItem.patientId} - {appointmentsItem.patientName}{' '}
          </Table.Cell>
          <Table.Cell>
            {' '}
            {appointmentsItem.doctorId} - {appointmentsItem.doctorName}{' '}
          </Table.Cell>
          <Table.Cell> {appointmentsItem.type} </Table.Cell>
          <Table.Cell> {appointmentsItem.practice?.name} </Table.Cell>
        </Table.Row>
      ))
    );

  const sortList = (param: string) => {
    setColumn(param);
    if (direction === 1) {
      setDirection(-1);
      setDirectionArrow('descending');
    } else if (direction === -1) {
      setDirection(1);
      setDirectionArrow('ascending');
    }
    const payload: any = { param, direction };
    dispatch(sortAppointments(payload));
  };

  const appointmentsListLoading = (
    <Loader className='loading-spinner' active inline='centered'>
      <FormattedMessage id='loading' />
    </Loader>
  );

  return (
    <div className='details_container'>
      {appointments.length !== 0 ? (
        <Table celled selectable sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'id' ? directionArrow : (null as any)}
                onClick={() => sortList('id')}
              >
                ID
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'startDate' ? directionArrow : (null as any)}
                onClick={() => sortList('startDate')}
              >
                <FormattedMessage id='appointment' />
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'patientName' ? directionArrow : (null as any)}
                onClick={() => sortList('patientName')}
              >
                <FormattedMessage id='patient' />
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'doctorName' ? directionArrow : (null as any)}
                onClick={() => sortList('doctorName')}
              >
                <FormattedMessage id='doctor' />
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'type' ? directionArrow : (null as any)}
                onClick={() => sortList('type')}
              >
                <FormattedMessage id='appointmentType' />
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'practice' ? directionArrow : (null as any)}
                onClick={() => sortList('practice')}
              >
                <FormattedMessage id='practice' />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{renderAppointmentsList(appointments)}</Table.Body>
        </Table>
      ) : (
        appointmentsListLoading
      )}
    </div>
  );
}
