import { takeLatest, put, select, call } from 'redux-saga/effects';
import { authSliceSelector, setAuthError } from '../auth/authSlice';
import { fetchPrescriptionsListCall } from '../../utils/apiCalls';
import {
  fetchPrescriptions,
  setPrescriptionsOpen,
  setPrescriptionsClose,
  setAllPrescriptions,
} from './prescriptionsSlice';
import { PrescriptionTask } from '../../types/prescriptionTask.types';

export interface ResponseData {
  data: {
    open: Array<PrescriptionTask>;
    closed: Array<PrescriptionTask>;
  };
  status: number;
  statusText: string;
}

function* fetchPrescriptionsSaga() {
  try {
    const { token } = yield select(authSliceSelector);
    const res: ResponseData = yield call(fetchPrescriptionsListCall, token);
    if (res.data) {
      if (res.status !== 200) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setAllPrescriptions(res.data));
        const filterByNordOpenTasks = res.data.open.filter((task: PrescriptionTask) => task.hub === 1);
        const filterByNordClosedTasks = res.data.closed.filter((task: PrescriptionTask) => task.hub === 1);

        yield put(setPrescriptionsOpen(filterByNordOpenTasks));
        yield put(setPrescriptionsClose(filterByNordClosedTasks));
        yield put(setAuthError(null));
      }
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

export function* watchPrescriptionsSagas() {
  yield takeLatest(fetchPrescriptions.type, fetchPrescriptionsSaga);
}
