const isSameDay = (date1: string, date2: string) => {
  if (new Date(date1).toDateString() === new Date(date2).toDateString()) {
    return true;
  }

  return false;
};

const getDateFormat = (date: string | number | Date) => {
  const newFormat = new Date(date).toLocaleString('de-DE', {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });

  return newFormat.slice(0, 2) + newFormat.slice(3);
};

const getHourMinutesFormat = (date: string): string => {
  const newFormat = new Date(date).toLocaleString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return newFormat;
};

export default {
  isSameDay,
  getDateFormat,
  getHourMinutesFormat,
};
