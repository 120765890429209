/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import services from '../../../../utils/services';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Icon, Table } from 'semantic-ui-react';
import { doctorsSliceSelector, deleteAbsense, saveAbsense } from '../../doctorsSlice';
import { toast } from 'react-toastify';

export default function DoctorInfoAbsencesItem(props: any) {
  const [editAbsence, setEditAbsence] = useState(-1);
  const [isEditable, setIsEditable] = useState(false);
  const [fromDateValue, setFromDateValue] = useState(new Date());
  const [toDateValue, setToDateValue] = useState(new Date());
  const [valuesForSubDoctors, setValuesForSubDoctors] = useState([]);
  const [displaySubDoctorsValuesNames, setDisplaySubDoctorsValuesNames] = useState([]);
  const { item, i, doctorId } = props;
  const { doctorsList } = useSelector(doctorsSliceSelector);
  const absencesSubDoctorsOptions = services.getAbsencesSubDoctorsOptions(doctorsList, doctorId);
  const dispatch = useDispatch();

  useEffect(() => {
    // @ts-ignore
    if (JSON.parse(item.subDoctorsIds).length) {
      // @ts-ignore
      const subDoctorName = services.convertDoctorIdToName(JSON.parse(item.subDoctorsIds), doctorsList || []);
      setDisplaySubDoctorsValuesNames(subDoctorName);
    } else setDisplaySubDoctorsValuesNames([]);
  }, [item.subDoctorsIds]);

  const onEditAbsence = () => {
    setEditAbsence(i);
    setIsEditable(!isEditable);
    // @ts-ignore
    setFromDateValue(new Date(item.startDate));
    // @ts-ignore
    setToDateValue(new Date(item.endDate));
    // @ts-ignore
    setValuesForSubDoctors(JSON.parse(item.subDoctorsIds));
  };

  const dateValidationFrom = (fromDateValue: any, toDateValue: any) => {
    if (fromDateValue > toDateValue) {
      return true;
    } else return false;
  };

  const dateValidationTo = (toDateValue: any, fromDateValue: any) => {
    if (toDateValue < fromDateValue) {
      return true;
    } else return false;
  };

  const onSaveAbsence = () => {
    const vlidatorFromDate = dateValidationFrom(fromDateValue, toDateValue);
    const vlidatorToDate = dateValidationTo(toDateValue, fromDateValue);
    if (vlidatorFromDate) {
      toast.error('Start date must be before end date!');
    } else if (vlidatorToDate) {
      toast.error('End date must be after start date!');
    } else {
      const absenseForSave: any = {
        ...item,
        subDoctorsIds: JSON.stringify(valuesForSubDoctors),
        startDate: fromDateValue,
        endDate: toDateValue,
      };
      dispatch(saveAbsense(absenseForSave));
      setEditAbsence(-1);
      setIsEditable(!isEditable);
    }
  };

  const getAbsenceDateFormat = (date: string) => {
    const newFormat = new Date(date).toLocaleString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });

    return newFormat;
  };

  const changeFromDate = (event: any, date: any) => {
    setFromDateValue(date.value);
  };

  const changeToDate = (event: any, date: any) => {
    setToDateValue(date.value);
  };

  const onDeleteAbsence = () => {
    // @ts-ignore
    dispatch(deleteAbsense(item.id));
  };

  const onUpdateSubDoctorsList = (evt: any, { value }: any) => {
    setValuesForSubDoctors(value);
  };

  const renderSubDoctorsNames = (displaySubDoctorsValuesNames: any) =>
    !displaySubDoctorsValuesNames.length ? (
      <FormattedMessage id='notSelectedDoctor' />
    ) : (
      displaySubDoctorsValuesNames.map((name: any) => `${name} `)
    );

  return (
    <Table.Row key={i}>
      <Table.Cell>
        {' '}
        {isEditable && editAbsence === i ? (
          <SemanticDatepicker onChange={(e, date) => changeFromDate(e, date)} value={fromDateValue} />
        ) : (
          getAbsenceDateFormat(item.startDate)
        )}
      </Table.Cell>
      <Table.Cell>
        {' '}
        {isEditable && editAbsence === i ? (
          <SemanticDatepicker onChange={(e, date) => changeToDate(e, date)} value={toDateValue} />
        ) : (
          getAbsenceDateFormat(item.endDate)
        )}
      </Table.Cell>
      <Table.Cell>
        {isEditable && editAbsence === i ? (
          <Dropdown
            placeholder='Select Sub Doctors'
            fluid
            multiple
            selection
            options={absencesSubDoctorsOptions}
            value={valuesForSubDoctors}
            onChange={onUpdateSubDoctorsList}
          />
        ) : (
          renderSubDoctorsNames(displaySubDoctorsValuesNames)
        )}
      </Table.Cell>
      <Table.Cell>
        {isEditable && editAbsence === i ? (
          <Button circular icon onClick={() => onSaveAbsence()}>
            <Icon color='teal' name='save outline' />
          </Button>
        ) : (
          <Button circular icon onClick={() => onEditAbsence()}>
            <Icon color='teal' name='edit outline' />
          </Button>
        )}
        {isEditable && editAbsence === i ? null : (
          <Button circular icon onClick={() => onDeleteAbsence()}>
            <Icon color='red' name='trash alternate outline' />
          </Button>
        )}
      </Table.Cell>
    </Table.Row>
  );
}
