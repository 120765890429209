/* eslint-disable @typescript-eslint/no-explicit-any */
const getAbsencesSubDoctorsOptions = (doctotrsList: any, doctorId: any) =>
  (doctotrsList || [])
    .filter((doctor: any) => doctor.id !== Number(doctorId))
    .map((doctor: any) => ({
      key: doctor.id,
      value: doctor.id,
      text: `${doctor.firstname} ${doctor.lastname}`,
    }));

const convertDoctorIdToName = (doctorAbsencesId: any, doctotrsList: any) =>
  doctorAbsencesId.map((doctorId: any) => {
    const userFound = doctotrsList.find((doc: any) => doc.id === doctorId);
    return `${userFound.firstname} ${userFound.lastname}`;
  });

export default {
  getAbsencesSubDoctorsOptions,
  convertDoctorIdToName,
};
