import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames/bind';
import type { Appointment, DoctorPatientRelation, Practice } from '../../../../types/ninox.types';
import { getLocalizedDate, getLocalizedDateTime } from '../../../../utils';
import { emptyAppointment } from '../../../../utils/emptyModels';
import FormDialog from '../../FormDialog/FormDialog';
import FormField from '../../FormField/FormField';
import style from '../Modal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { patientInfoSliceSelector, setPatientInfoUpdate } from '../../../../features/patientInfo/patientInfoSlice';
import _ from 'lodash';
import { createPatientAppointment, fetchDoctorAppointments, fetchDoctorPractices } from '../../../../utils/apiCalls';
import { toast } from 'react-toastify';

const cx = classNames.bind(style);

export type AppointmentModalProps = {
  type: 'appointment';
  show: boolean;
  setShow: (show: boolean) => void;
  appointment: Appointment | null;
  patientId: number;
  doctors: DoctorPatientRelation[];
};

export function AppointmentModal(props: AppointmentModalProps): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [doctorAppointments, setDoctorAppointments] = useState<Appointment[]>([]);
  const [doctorPractices, setDoctorPractices] = useState<Practice[]>([]);
  const [doctorDateAppointments, setDoctorDateAppointments] = useState<Appointment[]>([]);
  const [appointment, setAppointment] = useState<Appointment>(emptyAppointment);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { patientInfoUpdate } = useSelector(patientInfoSliceSelector);

  const filterDoctorAppointmentsForDate = (appointments: Appointment[]): Appointment[] => {
    if (!appointments) {
      return [];
    }
    return appointments.filter(
      (doctorAppointment) =>
        getLocalizedDate(intl, doctorAppointment.startDate) === getLocalizedDate(intl, appointment.startDate)
    );
  };

  const createAppointment = () => {
    // Validation checks for required fields
    if (appointment.type === 'offline' && appointment.practiceId === 0) {
      setErrorMessage('Keine Praxis gewählt');
      return;
    }
    if (appointment.doctorId === 0) {
      setErrorMessage('Kein Arzt gewählt');
      return;
    }
    if (appointment.startDate === '') {
      setErrorMessage('Kein Startdatum gewählt');
      return;
    }

    // Set doctor name for appointment
    props.doctors.forEach((doctor) => {
      if (doctor.doctorId === appointment.doctorId) {
        appointment.doctorName = `${doctor.doctor?.firstname} ${doctor.doctor?.lastname}`;
      }
    });

    // Call API to create the appointment
    createPatientAppointment(appointment)
      .then((response) => {
        // Check if the response indicates an error
        if (response.response?.status === 400) {
          const errorMessage = response.response.data.message;
          // Check for "Appointment collision" message
          if (errorMessage.includes('Appointment collision')) {
            setErrorMessage('Terminkollision. Bitte überprüfen Sie die Angaben.');
          }
          // Prevent the modal from closing in case of an error
          return;
        }

        props.setShow(false);

        // Reset state after successful creation
        setAppointment({ ...emptyAppointment, patientId: props.patientId });
        appointment.id = '';
        appointment.type = appointment.type === 'online' ? '1' : '2';

        // Check if `patientInfoUpdate.appointment` is an array, and handle accordingly
        let updatedAppointments = [];
        if (Array.isArray(patientInfoUpdate.appointment)) {
          updatedAppointments = _.cloneDeep(patientInfoUpdate.appointment);
        }

        // Add the newly created appointment
        updatedAppointments.push(appointment);

        // Update Redux store with the new appointment information
        dispatch(setPatientInfoUpdate({ ...patientInfoUpdate, appointment: updatedAppointments }));

        //show success message
        toast.success('Termin wurde erstellt.');

        //clear error message
        setErrorMessage('');
      })
      .catch((err) => {
        // Catching network or unexpected errors
        console.error('Network or unexpected error while creating appointment:', err);
        setErrorMessage('Netzwerkfehler: Bitte versuchen Sie es später noch einmal.');
      });
  };

  useEffect(() => {
    if (props.appointment) {
      setAppointment(props.appointment);
    }
  }, [props.appointment]);

  useEffect(() => {
    fetchDoctorAppointments(appointment.doctorId).then((response) => {
      if (response.error) {
        return;
      }

      if (!response.data) {
        setDoctorAppointments([]);
        setDoctorDateAppointments([]);
      } else {
        setDoctorAppointments(response.data);
        setDoctorDateAppointments(filterDoctorAppointmentsForDate(response.data));
      }
    });
    fetchDoctorPractices(appointment.doctorId).then((response) => {
      if (response.error) {
        return;
      }
      setDoctorPractices(response.data);
    });
  }, [appointment.doctorId]);

  useEffect(() => {
    if (appointment.startDate) {
      setDoctorDateAppointments(filterDoctorAppointmentsForDate(doctorAppointments));
      const startDate = new Date(appointment.startDate).getTime();
      const oneHour = 60 * 60 * 1000;
      const timeOffset = (new Date().getTimezoneOffset() / 60) * -1;
      const thirtyMinutes = 30 * 60 * 1000;
      const fifteenMinutes = 15 * 60 * 1000;

      const endDate = new Date(
        startDate +
          oneHour * timeOffset +
          (appointment.appointmentType === 'followUpAppointment' ? fifteenMinutes : thirtyMinutes)
      )
        .toISOString()
        .replace('Z', '');

      setAppointment({ ...appointment, endDate });
    }
  }, [appointment.startDate, appointment.appointmentType]);

  useEffect(() => {
    setAppointment({ ...appointment, patientId: props.patientId });
  }, [props.patientId]);

  const listClasses = cx({ listHeight: true });
  const errorMessageClasses = cx({ errorMessage: true });

  return (
    <FormDialog
      title={intl.formatMessage({ id: 'createAppointment' })}
      onSave={() => createAppointment()}
      onClose={() => props.setShow(false)}
      setShow={() => props.setShow(false)}
      show={props.show}
    >
      <FormField
        type='select'
        name='doctorId'
        value={appointment.doctorId.toString()}
        onChange={(event) => setAppointment({ ...appointment, doctorId: Number(event.target.value) })}
        choices={[['0', '']].concat(
          props.doctors.map((doctor) => [
            doctor.doctorId?.toString() || '0',
            `${doctor.doctor?.title ? intl.formatMessage({ id: doctor.doctor.title }) : ''} 
            ${doctor.doctor?.firstname} ${doctor.doctor?.lastname}`,
          ])
        )}
      >
        <FormattedMessage id='doctor' />
      </FormField>
      <FormField
        type='select'
        name='type'
        value={appointment.type}
        onChange={(event) => setAppointment({ ...appointment, type: event.target.value })}
        choices={[
          ['online', 'Online'],
          ['offline', 'Offline'],
        ]}
      >
        <FormattedMessage id='appointmentType' />
      </FormField>
      {appointment.type === 'offline' && appointment.doctorId !== 0 && (
        <FormField
          type='select'
          name='type'
          value={appointment.practiceId.toString()}
          onChange={(event) => setAppointment({ ...appointment, practiceId: Number(event.target.value) })}
          choices={[['0', '']].concat(
            doctorPractices.map((practice) => [practice.id?.toString() || '0', `${practice.name}`])
          )}
        >
          <FormattedMessage id='practice' />
        </FormField>
      )}
      <FormField
        type='select'
        name='appointmentSubtype'
        value={appointment.appointmentType}
        onChange={(event) => setAppointment({ ...appointment, appointmentType: event.target.value })}
        choices={[
          ['firstAppointment', 'Erstgespräch'],
          ['followUpAppointment', 'Folgetermin'],
        ]}
      >
        <FormattedMessage id='appointmentSubtype' />
      </FormField>
      <FormField
        type='datetime-local'
        name='startDate'
        value={appointment.startDate}
        onChange={(event) => setAppointment({ ...appointment, startDate: event.target.value })}
      >
        <FormattedMessage id='startData' />
      </FormField>
      <FormField
        type='datetime-local'
        name='endDate'
        value={appointment.endDate}
        onChange={(event) => setAppointment({ ...appointment, endDate: event.target.value })}
      >
        <FormattedMessage id='endDate' />
      </FormField>
      <FormField
        type='textarea'
        placeholder={intl.formatMessage({ id: 'patient.enterRemark' })}
        name='comment'
        value={appointment.comment}
        onChange={(event) => setAppointment({ ...appointment, comment: event.target.value })}
      >
        <FormattedMessage id='patient.message' />
      </FormField>
      <div>
        <FormattedMessage id='doctorAppointments' />:
      </div>
      <div className={listClasses}>
        {doctorDateAppointments.length === 0 && <FormattedMessage id='noAppointments' />}
        {doctorDateAppointments.map((doctorAppointment) => (
          <div key={doctorAppointment.id}>
            {getLocalizedDateTime(intl, doctorAppointment.startDate)} <b>-</b>
            {getLocalizedDateTime(intl, doctorAppointment.endDate)}
          </div>
        ))}
      </div>
      <div className={errorMessageClasses}>{errorMessage}</div>
    </FormDialog>
  );
}
