/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { isAxiosError, AxiosResponse } from 'axios';
import { request } from './request';
import {
  CREATE_DOCTOR,
  GET_ALL_DOCTORS_APPOINTMENTS_STATS,
  GET_DOCTOR,
  GET_APPOINTMENT_STATS,
  GET_FREE_APPOINTMENTS,
  GET_DOCTOR_ABSENCES,
  DELETE_DOCTOR_ABSENCE,
  UPDATE_DOCTOR_ABSENCE,
  CREATE_DOCTOR_ABSENCES,
  LOGIN_USER,
  FETCH_PATIENTS,
  FETCH_APPOINTMENTS,
  FETCH_PRACTICES,
  GET_FREE_APPOINTMENTS_PRACTICE,
  GET_PATIENT_DOCTOR_LIST,
  FETCH_PRESCRIPTIONS,
  SEND_PRESCRIPTIONS,
  GET_ALL_DOCTORS,
  GET_PATIENT,
  GET_PATIENT_FILES,
  GET_PATIENT_APPOINTMENTS,
  GET_PATIENT_REMARKS,
  GET_PATIENT_PAYMENTS,
  GET_PAYMENT_INVOICE,
  ASSIGN_PATIENT_DOCTORS,
  FETCH_DOCTOR_APPOINTMENTS,
  FETCH_DOCTOR_PRACTICES,
  CREATE_APPOINTMENT,
  EDIT_PATIENT,
  ACCEPT_DATA,
  DELETE_PATIENT,
  DELETE_PATIENT_COMPLAINTS,
  CREATE_REMARK,
  DELETE_REMARK,
  DELETE_ADDRESS,
  FILE_RELEVANCE_STATUS,
  FILE_TYPE,
  UPLOAD_FILE,
  DELETE_FILE,
  GET_FILE,
  LOAD_FILES,
  CREATE_OFFLINE_PRACTICE_APPOINTMENT,
} from '../constants';
import type { Appointment, Doctor, DoctorPatientRelation, FileType, Patient, Remark } from '../types/ninox.types';
import type { UserType } from '../types/userTypes';
import type { Absence } from '../types/absence.type';
import { getAPIToken } from '../utils';

type ResponseType = AxiosResponse | any;
export interface RequestData {
  url: string | null;
  method: string | null;
  body: object | null;
}

// AUTH CALLS
export const loginUser = async (user: UserType) => {
  const requestOptions = {
    url: LOGIN_USER,
    method: 'POST',
    body: user,
  };

  let response;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

//PATIENTS CALLS
export const fetchPatientsListCall = async (token: string, body: any) => {
  const requestOptions = {
    url: FETCH_PATIENTS,
    method: 'POST',
    token: `Bearer ${token}`,
    body: body,
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

//PATIENT CALLS
export const getPatient = async (id: number) => {
  const requestOptions = {
    url: GET_PATIENT + id,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const getPatientFiles = async (patientID: number) => {
  const requestOptions = {
    url: GET_PATIENT_FILES(patientID),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const getFile = async (fileId: number) => {
  const requestOptions = {
    url: GET_FILE(fileId),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const loadFiles = async (patientID: number) => {
  const requestOptions = {
    url: LOAD_FILES(patientID),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const getPatientRemarks = async (patientID: number) => {
  const requestOptions = {
    url: GET_PATIENT_REMARKS(patientID),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const getPatientAppointments = async (patientID: number) => {
  const requestOptions = {
    url: GET_PATIENT_APPOINTMENTS(patientID),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const getPatientPayments = async (patientId: number): Promise<ResponseType> => {
  try {
    const response = await request({
      url: GET_PATIENT_PAYMENTS(patientId),
      method: 'GET',
      token: `Bearer ${getAPIToken()}`,
      body: {},
    });
    return response;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error;
    } else {
      throw new Error('An error occurred in getPatientPayments');
    }
  }
};

export const getPaymentInvoice = async (paymentId: number): Promise<ResponseType> => {
  try {
    const response = await request({
      url: GET_PAYMENT_INVOICE(paymentId),
      method: 'GET',
      token: `Bearer ${getAPIToken()}`,
      body: {},
      responseType: 'blob' as const,
    });
    return response;
  } catch (error) {
    if (isAxiosError(error)) {
      throw error;
    } else {
      throw new Error('An error occurred in getPaymentInvoice');
    }
  }
};

export const assignDoctors = async (patientId: number, relation: DoctorPatientRelation[]) => {
  const requestOptions = {
    url: ASSIGN_PATIENT_DOCTORS(patientId),
    method: 'POST',
    token: `Bearer ${getAPIToken()}`,
    body: relation,
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const changeRelevantFileStatus = async (patientId: number, fileId: number) => {
  const requestOptions = {
    url: FILE_RELEVANCE_STATUS(patientId, fileId),
    method: 'PUT',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const changeFileType = async (patientId: number, fileType: FileType) => {
  const requestOptions = {
    url: FILE_TYPE(patientId, fileType.fileId),
    method: 'PUT',
    token: `Bearer ${getAPIToken()}`,
    body: { type: fileType.type },
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const uploadPatientFile = async (patientId: number, formData: FormData) => {
  const requestOptions = {
    url: UPLOAD_FILE(patientId),
    method: 'POST',
    isMultipart: true,
    token: `Bearer ${getAPIToken()}`,
    body: formData,
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const createPatientRemark = async (patientId: number, remark: Remark) => {
  const requestOptions = {
    url: CREATE_REMARK(patientId),
    method: 'POST',
    token: `Bearer ${getAPIToken()}`,
    body: remark,
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const deletePatientRemark = async (patientId: number, remarkId: number) => {
  const requestOptions = {
    url: DELETE_REMARK(patientId, remarkId),
    method: 'DELETE',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const deletePatientFile = async (patientId: number, fileId: number) => {
  const requestOptions = {
    url: DELETE_FILE(patientId, fileId),
    method: 'DELETE',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const deletePatientAddress = async (patientId: number, addressId: number) => {
  const requestOptions = {
    url: DELETE_ADDRESS(patientId, addressId),
    method: 'DELETE',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const editPatientInfo = async (patientId: number, data: Patient) => {
  const requestOptions = {
    url: EDIT_PATIENT(patientId),
    method: 'PUT',
    token: `Bearer ${getAPIToken()}`,
    body: data,
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const acceptPatientData = async (patientId: number) => {
  const requestOptions = {
    url: ACCEPT_DATA(patientId),
    method: 'PUT',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const deletePatientCall = async (patientId: number) => {
  const requestOptions = {
    url: DELETE_PATIENT(patientId),
    method: 'DELETE',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const deletePatientComplaints = async (patientId: number, complaintId: number) => {
  const requestOptions = {
    url: DELETE_PATIENT_COMPLAINTS(patientId, complaintId),
    method: 'DELETE',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

// APPOINTMENTS CALLS
export const fetchAppointmentsListCall = async (token: string) => {
  const requestOptions = {
    url: FETCH_APPOINTMENTS,
    method: 'GET',
    token: `Bearer ${token}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const fetchDoctorAppointments = async (doctorID: number) => {
  const requestOptions = {
    url: FETCH_DOCTOR_APPOINTMENTS(doctorID),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const createPatientAppointment = async (appointment: Appointment) => {
  const requestOptions = {
    url: CREATE_APPOINTMENT,
    method: 'POST',
    token: `Bearer ${getAPIToken()}`,
    body: appointment,
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

// DOCTORS CALLS
export const createDoctor = async (doctor: Doctor) => {
  const requestOptions = {
    url: CREATE_DOCTOR,
    method: 'POST',
    token: `Bearer ${getAPIToken()}`,
    body: doctor,
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const fetchDoctorsAppointmentsStatsCall = async (token: string) => {
  const requestOptions = {
    url: GET_ALL_DOCTORS_APPOINTMENTS_STATS,
    method: 'GET',
    token: `Bearer ${token}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const getDoctor = async (id: number) => {
  const requestOptions = {
    url: GET_DOCTOR + id,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const getAppointmentStats = async () => {
  const requestOptions = {
    url: GET_APPOINTMENT_STATS,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const getFreeDoctorSlots = async (doctorId: number, url: string) => {
  const requestOptions = {
    url: GET_FREE_APPOINTMENTS(doctorId, url),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const getDoctorAbsences = async (doctorId: number) => {
  const requestOptions = {
    url: GET_DOCTOR_ABSENCES(doctorId),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const deleteDoctorAbsence = async (absenceId: string) => {
  const requestOptions = {
    url: DELETE_DOCTOR_ABSENCE + absenceId,
    method: 'DELETE',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const updateAbsence = async (data: Absence, absenceId: string) => {
  const requestOptions = {
    url: UPDATE_DOCTOR_ABSENCE + absenceId,
    method: 'PUT',
    token: `Bearer ${getAPIToken()}`,
    body: data,
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const createAbsence = async (absence: Absence, doctorId: number) => {
  const requestOptions = {
    url: CREATE_DOCTOR_ABSENCES(doctorId),
    method: 'POST',
    token: `Bearer ${getAPIToken()}`,
    body: absence,
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const fetchAllDoctorsCall = async () => {
  const requestOptions = {
    url: GET_ALL_DOCTORS,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

// PRACTICESS CALL
export const fetchPracticesListCall = async (token: string) => {
  const requestOptions = {
    url: FETCH_PRACTICES,
    method: 'GET',
    token: `Bearer ${token}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};
export const fetchDoctorPractices = async (doctorId: number) => {
  const requestOptions = {
    url: FETCH_DOCTOR_PRACTICES(doctorId),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const getFreeAppointmentsPracticeCall = async (token: string, practiceId: number) => {
  const requestOptions = {
    url: GET_FREE_APPOINTMENTS_PRACTICE(practiceId),
    method: 'GET',
    token: `Bearer ${token}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const fetchPatientsDoctorListCall = async (token: string, patientId: number) => {
  const requestOptions = {
    url: GET_PATIENT_DOCTOR_LIST(patientId),
    method: 'GET',
    token: `Bearer ${token}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const fetchPrescriptionsListCall = async (token: string) => {
  const requestOptions = {
    url: FETCH_PRESCRIPTIONS,
    method: 'GET',
    token: `Bearer ${token}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const sendPrescriptionCall = async (token: string) => {
  const requestOptions = {
    url: SEND_PRESCRIPTIONS,
    method: 'POST',
    token: `Bearer ${token}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};

export const createOfflinePracticeAppointment = async (token: string, appointmentData: Appointment) => {
  const requestOptions = {
    url: CREATE_OFFLINE_PRACTICE_APPOINTMENT,
    method: 'POST',
    token: `Bearer ${token}`,
    body: {
      appointmentData,
    },
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};
