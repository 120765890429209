/* eslint-disable @typescript-eslint/no-explicit-any */
import { takeLatest, put, select, call } from 'redux-saga/effects';
import { fetchAppointments, setAppointments, setLoadAppointments } from './appointmentsSlice';
import { authSliceSelector, setAuthError } from '../auth/authSlice';
import { fetchAppointmentsListCall } from '../../utils/apiCalls';
export interface ResponseData {
  data: Array<any> | null;
  status: number;
  statusText: string;
}

function* fetchAppointmentsSaga(action: any) {
  try {
    yield put(setLoadAppointments(true));
    const { token } = yield select(authSliceSelector);
    const res: ResponseData = yield call(fetchAppointmentsListCall, token);
    if (res.data) {
      if (res.status !== 200) {
        yield put(setAuthError(res.statusText));
      } else {
        yield put(setAppointments(res.data));
        yield put(setLoadAppointments(false));
        yield put(setAuthError(null));
      }
    }
  } catch (err) {
    yield put(setAuthError(err));
    // eslint-disable-next-line no-console
    console.error('New error', err);
  }
}

export function* watchAppointmentsSaga() {
  yield takeLatest(fetchAppointments.type, fetchAppointmentsSaga);
}
