const { REACT_APP_API_URL } = process.env;

export const API_URL = REACT_APP_API_URL;

export const RED_URL = process.env.REACT_APP_RED_MEDICAL_URL;

// auth
export const LOGIN_USER = `${API_URL}/user/authenticate`;

// Doctor
export const CREATE_DOCTOR = `${API_URL}/doctor`;
export const GET_ALL_DOCTORS = `${API_URL}/doctor`;
export const GET_ALL_DOCTORS_APPOINTMENTS_STATS = `${API_URL}/doctor/appointment-stats`;
export const GET_DOCTOR = `${API_URL}/doctor/`;
export const GET_APPOINTMENT_STATS = `${API_URL}/doctor/appointment-stats`;
export const GET_FREE_APPOINTMENTS = (doctorID: number, url: string) => `${API_URL}/doctor/${doctorID}/${url}`;
export const GET_DOCTOR_ABSENCES = (doctorID: number) => `${API_URL}/doctor/${doctorID}/absence`;
export const DELETE_DOCTOR_ABSENCE = `${API_URL}/doctor/absence/`;
export const UPDATE_DOCTOR_ABSENCE = `${API_URL}/doctor/absence/`;
export const CREATE_DOCTOR_ABSENCES = (doctorID: number) => `${API_URL}/doctor/${doctorID}/absence`;

//Patients
export const FETCH_PATIENTS = `${API_URL}/patient`;
export const GET_PATIENT_DOCTOR_LIST = (patientId: number) => `${API_URL}/patient/${patientId}/doctors`;

//Patient
export const GET_PATIENT = `${API_URL}/patient/`;
export const GET_PATIENT_FILES = (patientID: number) => `${API_URL}/patient/${patientID}/file`;
export const LOAD_FILES = (patientID: number) => `${API_URL}/patient/${patientID}/file`;
export const GET_FILE = (fileID: number) => `${API_URL}/document/${fileID}`;
export const GET_PATIENT_REMARKS = (patientID: number) => `${API_URL}/patient/${patientID}/remark`;
export const GET_PATIENT_APPOINTMENTS = (patientID: number) => `${API_URL}/appointment/patient/${patientID}`;
export const GET_PATIENT_PAYMENTS = (patientID: number) => `${API_URL}/patient/${patientID}/payments`;
export const GET_PAYMENT_INVOICE = (paymentId: number) => `${API_URL}/invoice/${paymentId}`;
export const FILE_RELEVANCE_STATUS = (patientID: number, fileID: number) =>
  `${API_URL}/patient/${patientID}/file/relevant/${fileID}`;
export const FILE_TYPE = (patientID: number, fileID: number) => `${API_URL}/patient/${patientID}/file/type/${fileID}`;
export const UPLOAD_FILE = (patientID: number) => `${API_URL}/patient/${patientID}/file`;
export const ASSIGN_PATIENT_DOCTORS = (patientID: number) => `${API_URL}/patient/${patientID}/doctors`;
export const CREATE_REMARK = (patientID: number) => `${API_URL}/patient/${patientID}/remark`;
export const DELETE_REMARK = (patientID: number, remarkID: number) =>
  `${API_URL}/patient/${patientID}/remark/${remarkID}`;
export const DELETE_FILE = (patientID: number, fileID: number) => `${API_URL}/patient/${patientID}/file/${fileID}`;
export const DELETE_ADDRESS = (patientID: number, addressID: number) =>
  `${API_URL}/patient/${patientID}/address/${addressID}`;
export const EDIT_PATIENT = (patientID: number) => `${API_URL}/patient/${patientID}`;
export const ACCEPT_DATA = (patientID: number) => `${API_URL}/patient/confirmData/${patientID}`;
export const DELETE_PATIENT = (patientID: number) => `${API_URL}/patient/${patientID}`;
export const DELETE_PATIENT_COMPLAINTS = (patientID: number, complaintID: number) =>
  `${API_URL}/patient/${patientID}/complaints/${complaintID}`;

//Appointments
export const FETCH_APPOINTMENTS = `${API_URL}/appointment`;
export const FETCH_DOCTOR_APPOINTMENTS = (doctorID: number) => `${API_URL}/appointment/doctor/${doctorID}`;
export const CREATE_APPOINTMENT = `${API_URL}/appointment/`;

//Practices
export const FETCH_PRACTICES = `${API_URL}/practice/free-appointments-stats`;
export const GET_FREE_APPOINTMENTS_PRACTICE = (practiceId: number) =>
  `${API_URL}/practice/${practiceId}/free-appointments`;
export const GET_FREE_APPOINTMENTS_STATS = `${API_URL}/practice/free-appointments-stats`;
export const FETCH_DOCTOR_PRACTICES = (doctorID: number) => `${API_URL}/practice/doctor/${doctorID}`;
export const CREATE_OFFLINE_PRACTICE_APPOINTMENT = `${API_URL}/appointment/practice/offline`;

//Prescriptions
export const FETCH_PRESCRIPTIONS = `${API_URL}/support/prescription-tasks`;
export const SEND_PRESCRIPTIONS = `${API_URL}/support/send-prescription`;
