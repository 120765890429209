/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Icon } from 'semantic-ui-react';
import { Col, Row } from 'react-bootstrap';
import { FormField } from '../../../components/molecules';
import { Doctor, DoctorPatientRelation } from '../../../types/ninox.types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doctorsSliceSelector, fetchAllDoctors } from '../../doctors/doctorsSlice';
import { patientInfoSliceSelector, setPatientDoctorsList } from '../patientInfoSlice';
import _ from 'lodash';

type EditPatientProps = {
  activeIndex: number;
  handleClick: (arg0: any, arg1: any) => void;
  patientId: number;
};

export default function PatientDoctor(props: EditPatientProps) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { doctorsList } = useSelector(doctorsSliceSelector);
  const { patientDoctorsList, patientInfoUpdate } = useSelector(patientInfoSliceSelector);

  const getPatientDoctorFieldValue = (type: string, fieldValue?: any): string => {
    if (typeof fieldValue !== 'undefined') {
      return fieldValue.doctorId.toString();
    }
    return '0';
  };

  const changeDoctor = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    const otherDoctor = patientDoctorsList.find((doctor: DoctorPatientRelation) => doctor.doctorType !== Number(name));
    const newDoctor = _.filter(doctorsList, { id: Number(value) });
    // @ts-ignore
    const doctorsRelation = [
      {
        doctorType: Number(name),
        doctorId: Number(value),
        patientId: props.patientId,
        doctor: newDoctor[0],
      } as DoctorPatientRelation,
    ];
    if (otherDoctor) {
      doctorsRelation.push({ ...otherDoctor });
    }
    dispatch(setPatientDoctorsList(doctorsRelation));
  };

  useEffect(() => {
    if (doctorsList.length === 0) {
      dispatch(fetchAllDoctors());
    }
  }, [doctorsList]);

  return (
    <>
      <Accordion.Title
        active={props.activeIndex === 0}
        index={0}
        onClick={(event, data) => props.handleClick(event, data)}
      >
        <Icon name='dropdown' />
        <FormattedMessage id='doctors' />
      </Accordion.Title>
      <Accordion.Content active={props.activeIndex === 0}>
        <Row>
          <Col>
            <FormField
              type='select'
              name='1'
              value={getPatientDoctorFieldValue(
                'string',
                patientDoctorsList.find((patientDoctor: DoctorPatientRelation) => patientDoctor.doctorType === 1)
              )}
              onChange={changeDoctor}
              choices={[['0', '']].concat(
                doctorsList.map((doctor: Doctor) => [
                  doctor.id?.toString() || '0',
                  `${doctor.title ? intl.formatMessage({ id: doctor.title }) : ''} ${doctor.firstname} 
                       ${doctor.lastname}`,
                ])
              )}
            >
              <FormattedMessage id='mainDoctor' />
            </FormField>
          </Col>
          <Col>
            <FormField
              type='select'
              name='2'
              value={getPatientDoctorFieldValue(
                'string',
                patientDoctorsList.find((patientDoctor: DoctorPatientRelation) => patientDoctor.doctorType === 2)
              )}
              onChange={changeDoctor}
              choices={[['0', '']].concat(
                doctorsList.map((doctor: Doctor) => [
                  doctor.id?.toString() || '0',
                  `${doctor.title ? intl.formatMessage({ id: doctor.title }) : ''} ${doctor.firstname} 
                      ${doctor.lastname}`,
                ])
              )}
            >
              <FormattedMessage id='subDoctor' />
            </FormField>
          </Col>
        </Row>
        {patientInfoUpdate.patientDataApproved && (
          <Row>
            <Col>
              <Col>
                <FormField
                  type='checkbox'
                  name='isDataAccepted'
                  value='Data accepted?'
                  checked={true}
                  disabled={true}
                  displayType='regularCheckbox'
                >
                  <FormattedMessage id='patient.patientEligibleForTherapy' />
                </FormField>
              </Col>
            </Col>
          </Row>
        )}
      </Accordion.Content>
    </>
  );
}
