/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames/bind';
import style from './Input.module.scss';

const cx = classNames.bind(style);

export type SelectOption = {
  label: string;
  value: string;
};

type InputProps = {
  type: string;
  name: string;
  placeholder?: string;
  value: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<any>) => void;
  onMultiSelectChange?: (name: string, options: readonly SelectOption[]) => void;
  choices?: string[][];
  language?: string;
  displayType?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
};

export function InputElement(props: InputProps): JSX.Element {
  const inputClasses = cx({ Input: true, RegularCheckbox: props.displayType === 'regularCheckbox' });

  const [multiSelectValue, setMultiSelectValue] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (props.type === 'multiselect') {
      const splitRe = /\s*(?:,|$)\s*/;

      const selectedOptions = props.value.split(splitRe);

      const filteredChoices = props.choices?.filter((choice) => selectedOptions.includes(choice[0]));

      let newValues = filteredChoices?.map((choice) => ({
        label: choice[1],
        value: choice[0],
      }));

      if (!newValues) {
        newValues = [];
      }

      setMultiSelectValue(newValues);
    }
  }, [props.value, props.language]);

  const onMultiSelectChange = (options: readonly SelectOption[]): void => {
    if (props.onMultiSelectChange) {
      props.onMultiSelectChange(props.name, options);
    }
  };

  if (props.type === 'textarea') {
    return (
      <textarea
        className={inputClasses}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        rows={6}
      ></textarea>
    );
  }

  if (props.type === 'select') {
    return (
      <div className={cx({ SelectBlock: true })}>
        <select name={props.name} value={props.value} onChange={props.onChange} className={cx({ Select: true })}>
          {props.choices?.map((choice) => (
            <option key={choice[0]} value={choice[0]}>
              {choice[1]}
            </option>
          ))}
        </select>
        <svg focusable='false' viewBox='0 0 24 24' aria-hidden='true'>
          <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'></path>
        </svg>
      </div>
    );
  }

  if (props.type === 'multiselect') {
    const options = props.choices?.map((choice) => ({ label: choice[1], value: choice[0] }));
    const customStyles = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      control: (provided: any, state: any) => ({
        ...provided,
        height: '100%',
        minHeight: 48,
        width: '100%',
        borderRadius: 0,
        borderColor: '#222222 !important',
        boxShadow: 'none',
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      menu: (provided: any, state: any) => ({
        ...provided,
        borderRadius: 0,
        borderColor: '#222222 !important',
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
      valueContainer: (provided: any, state: any) => ({
        ...provided,
        padding: '7px 8px',
      }),
    };

    return (
      <Select
        styles={customStyles}
        options={options}
        name={props.name}
        isMulti
        className={cx({ MultiSelect: true })}
        classNamePrefix='nowomed-multi-select'
        onChange={onMultiSelectChange}
        value={multiSelectValue}
      />
    );
  }

  return (
    <input
      className={inputClasses}
      type={props.type}
      name={props.name}
      checked={props.checked}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      min={props.min}
      max={props.max}
      disabled={props.disabled}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any) => ({ language: state.language });

export const Input = connect(mapStateToProps, null)(InputElement);
